require('./bootstrap');

window.mediaList = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400
};

window.mediaSizeMatch = function(media, gt = true) {
    for (mediaSize of Object.getOwnPropertyNames(mediaList)) {
        if (media !== mediaSize) {
            continue;
        }
        if (gt) {
            return document.querySelector('body').clientWidth > mediaList[mediaSize];
        }
        return document.querySelector('body').clientWidth < mediaList[mediaSize];
    }
}

window.load = (message) => {
    document.querySelector('.main-container').classList.remove('loaded');
    if (typeof message === 'string') {
        document.querySelector('.loader-content').innerHTML = message
        return;
    }
    if (typeof message === 'object' && Array.isArray(message)) {
        loadingRotation(message, 0);
    }
}

let loadingTimer = null
window.loadingRotation = (loadPackages, currentIdx) => {
    document.querySelector('.loader-content').innerHTML = loadPackages[currentIdx].message
    if (currentIdx === loadPackages.length - 1) {
        return;
    }

    loadingTimer = setTimeout(
        async () => {
            await new Promise(resolve => {
                document.querySelector('.loader-content').classList.add('up')
                loadingTimer = setTimeout(() => {
                    document.querySelector('.loader-content').classList.add('down')
                    document.querySelector('.loader-content').classList.remove('up')
                        resolve()
                    }, 330
                );
            });
            loadingRotation(loadPackages, currentIdx + 1)
            await new Promise(resolve => {
                loadingTimer = setTimeout(() => {
                        document.querySelector('.loader-content').classList.remove('down')
                        resolve()
                    }, 330
                );
            });
        },
        loadPackages[currentIdx].timer + 660
    )
}

// Use onbeforeunload instead of unload. Safari doesn't seem to support unload.
window.onbeforeunload = () => {
    if (loadingTimer) {
        window.clearTimeout(loadingTimer);
    }
    document.querySelector('.main-container').classList.add('loaded')
}
